<template>
    <v-dialog v-model="dialog" width="800px">
        <dialog-user-ban ref="dialog_user_ban" @user_banned="user_banned" :user="user"></dialog-user-ban>
        <dialog-confirm ref="confirm"></dialog-confirm>

        <div class="px-4 grey darken-3">
            <dragger></dragger>
            <v-row>
                <!--username-->
                <v-col cols="12" sm="6">
                    <v-form ref="r_form_change_username" class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="enable_username"
                                    :disabled="!$root.check_admin"
                                    :color="$variables.v.theme.secondary"></v-checkbox>

                        <v-text-field
                                v-model="user.username"
                                :label="$t('administration.user-administration.user.username')"
                                :color="$variables.v.theme.secondary"
                                :rules="[rules.required, rules.min3, rules.max30, rules.no_white_spaces]"
                                maxlength="30"
                                :disabled="!enable_username"
                                class="default-textfield-width"></v-text-field>

                        <v-icon :key="unlock_change_username"
                                :disabled="!$root.check_admin || user.username === user_backup.username"
                                :color="$variables.v.theme.secondary"
                                class="mx-5"
                                @click.once="update_username">
                            mdi-floppy
                        </v-icon>
                    </v-form>
                </v-col>

                <!--E-mail-->
                <v-col cols="12" sm="6">
                    <v-form ref="r_form_change_email" class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="enable_email"
                                    :color="$variables.v.theme.secondary"></v-checkbox>

                        <v-text-field
                                v-model="user.email"
                                :label="$t('administration.user-administration.user.email')"
                                :color="$variables.v.theme.secondary"
                                :rules="[rules.required, rules.no_white_spaces, rules.valid_email, rules.max150]"
                                maxlength="30"
                                :disabled="!enable_email"
                                class="default-textfield-width"></v-text-field>

                        <v-icon :key="unlock_change_email"
                                :disabled="!$root.check_admin || user.email === user_backup.email"
                                :color="$variables.v.theme.secondary"
                                class="mx-5"
                                @click.once="update_email">
                            mdi-floppy
                        </v-icon>
                    </v-form>
                </v-col>


                <!--Points-->
                <v-col cols="12" sm="6">
                    <v-form ref="r_form_change_points" class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="enable_points"
                                    :disabled="!$root.check_admin"
                                    :color="$variables.v.theme.secondary"></v-checkbox>

                        <v-text-field
                                v-model="user.points"
                                :label="$t('administration.user-administration.user.points')"
                                :color="$variables.v.theme.secondary"
                                maxlength="30"
                                :disabled="!enable_points"
                                class="default-textfield-width"></v-text-field>

                        <v-icon :key="unlock_change_points"
                                :disabled="!$root.check_admin || user.points === user_backup.points"
                                :color="$variables.v.theme.secondary"
                                class="mx-5"
                                @click.once="update_points">
                            mdi-floppy
                        </v-icon>
                    </v-form>
                </v-col>


                <!--Role-->
                <v-col cols="12" sm="6">
                    <v-form ref="r_form_change_points" class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="enable_role"
                                    :disabled="!c_role_change_allowed"
                                    :color="$variables.v.theme.secondary"></v-checkbox>

                        <v-select
                                v-model="user.roleID"
                                :items="c_roles"
                                item-value="id"
                                :color="$variables.v.theme.secondary"
                                dense
                                :disabled="!enable_role"
                                single-line
                                outlined
                                class="role-selection">
                            <template slot=selection slot-scope="data">
                                {{$t(data.item.name)}}
                            </template>
                            <template slot=item slot-scope="data">
                                {{$t(data.item.name)}}
                            </template>
                        </v-select>

                        <v-icon :key="unlock_change_role"
                                :disabled="user.roleID === user_backup.roleID"
                                :color="$variables.v.theme.secondary"
                                class="mx-5"
                                @click.once="update_role">
                            mdi-floppy
                        </v-icon>
                    </v-form>
                </v-col>

                <!--Deauthorize-->
                <v-col cols="12" :key="unlock_actions">
                    <v-btn depressed
                           class="administration-btn"
                           @click.once="deauthorize_user">
                        {{$t("administration.user-administration.deauthorize")}}
                    </v-btn>

                    <v-btn depressed
                           class="administration-btn"
                           @click.once="$router.push($variables.v.navigation.profile_id + user.userID)">
                        {{$t("administration.user-administration.open-profile")}}
                    </v-btn>
                </v-col>


                <!--Bans-->
                <v-col cols="12">
                    <v-expansion-panels>
                        <v-expansion-panel class="expansion-box">
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">{{$t("administration.user-administration.show-bans")}}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>

                                <v-row>
                                    <!--Ban user-->
                                    <v-col cols="12" class="text-center" v-if="!user.banned">
                                        <v-btn :key="unlock_ban_user"
                                               depressed
                                               class="administration-btn mb-5"
                                               @click.once="swing_banhammer">
                                            {{$t("administration.user-administration.ban-user")}}
                                        </v-btn>
                                    </v-col>


                                    <!--Revoke Ban (only admins can remove bans)-->
                                    <v-col cols="12" class="text-center" v-if="$root.check_admin() && user.banned">
                                        <v-btn depressed
                                               class="administration-btn mb-5"
                                               @click="revoke_ban">
                                            {{$t(user.banned === "permanent" ? "administration.user-administration.revoke-perm-ban" :
                                            "administration.user-administration.revoke-temp-ban")}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!--Ban list-->
                                <v-data-table
                                        v-if="bans.length > 0"
                                        :headers="headers"
                                        :items="bans"
                                        hide-default-footer
                                        multi-sort>
                                    <template slot="no-data">
                                        <div></div>
                                    </template>
                                    <template v-slot:item.duration="{ item }">
                                        {{item.permanent === "1" ? "permanent" : moment(item.ban_expiration).format("DD.MM.YYYY HH:mm") }}
                                    </template>
                                    <template v-slot:item.revoked="{ item }">
                                        {{item.revoked === "1" ? "X" : "" }}
                                    </template>
                                    <template v-slot:item.banned_date="{ item }">
                                        {{item.permanent === "1" ? "permanent" : moment(item.banned_date).format("DD.MM.YYYY HH:mm") }}
                                    </template>


                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

                <!--Extended Information-->
                <v-col cols="12">
                    <v-expansion-panels class="mb-5">
                        <v-expansion-panel class="expansion-box">
                            <v-expansion-panel-header>
                                <span class="font-weight-bold">{{$t("administration.user-administration.show-extended-information")}}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="dont-break-out">
                                <v-row dense class="extended-userinformation">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.verified")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.email_verified === "1"}}
                                    </v-col>
                                </v-row>


                                <v-row dense class="extended-userinformation" v-if="user.language">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.language")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.language}}
                                    </v-col>
                                </v-row>


                                <v-row dense class="extended-userinformation" v-if="user.banned">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.banned")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.banned === "permanent" ? user.banned : user.ban_expiration}}
                                    </v-col>
                                </v-row>


                                <v-row dense class="extended-userinformation" v-if="user.last_username_change">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.last-username-change")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.last_username_change}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.last_username_change">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.register-date")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.register_date}}
                                    </v-col>
                                </v-row>


                                <v-row dense class="extended-userinformation" v-if="user.last_login">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.last-login")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.last_login}}
                                    </v-col>
                                </v-row>


                                <v-row dense class="extended-userinformation" v-if="user.last_login_ip">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.last-login-ip")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.last_login_ip}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.browser_lang">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.browser-lang")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.browser_lang}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.browser_name">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.browser-name")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.browser_name}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.browser_useragent">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.browser-useragent")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.browser_useragent}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.browser_version">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.browser-version")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.browser_version}}
                                    </v-col>
                                </v-row>

                                <v-row dense class="extended-userinformation" v-if="user.screen_height">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.screen-height")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.screen_height}}
                                    </v-col>
                                </v-row>

                                <v-row dense v-if="user.screen_width">
                                    <v-col cols="4">
                                        {{$t("administration.user-administration.user.screen-width")}}
                                    </v-col>
                                    <v-col cols="8">
                                        {{user.screen_width}}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </div>
    </v-dialog>
</template>

<script>
    import dialog_user_ban from "./dialog-user-ban";


    export default {
        components: {
            "dialog-user-ban": dialog_user_ban
        },
        data() {
            return {
                dialog: false,
                valid : false, // Disable Button when form not valid

                unlock_change_username: 0,
                unlock_change_email   : 100000,
                unlock_change_points  : 200000,
                unlock_change_role    : 300000,
                unlock_actions        : 400000,
                unlock_ban_user       : 500000,

                user       : {},
                user_backup: {},

                bans: [],

                enable_username: false,
                enable_email   : false,
                enable_points  : false,
                enable_role    : false,

                rules  : {
                    required       : v => (!!v) || this.$t("rules.required"),
                    min3           : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    max30          : v => (v !== undefined && v.length <= 30) || this.$t("rules.max-30-chars"),
                    max150         : v => (v !== undefined && v.length <= 150) || this.$t("rules.max-150-chars"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                    valid_email    : v => (v !== undefined && /.+@.+\..+/.test(v)) || this.$t("rules.email-must-valid"),
                },
                headers: [
                    {text: this.$t("administration.user-administration.bans.id"), value: "ID"},
                    {text: this.$t("administration.user-administration.bans.duration"), value: "duration"},
                    {text: this.$t("administration.user-administration.bans.banned-by"), value: "banned_by"},
                    {text: this.$t("administration.user-administration.bans.revoked"), value: "revoked"},
                    {text: this.$t("administration.user-administration.bans.reason"), value: "reason"},
                    {text: this.$t("administration.user-administration.bans.banned-date"), value: "banned_date"},
                ],
            }
        },
        computed  : {
            c_role_change_allowed() {
                let role       = Number(this.$user.user.roleID);
                let userrole   = Number(this.user.roleID);
                let superadmin = this.$variables.v.role_ids.superadmin;
                let admin      = this.$variables.v.role_ids.admin;
                let moderator  = this.$variables.v.role_ids.moderator;

                if (role === superadmin)
                    return true;
                else if (role === admin && userrole !== superadmin && userrole !== admin)
                    return true;
                else if (role === moderator && userrole !== superadmin && userrole !== admin && userrole !== moderator)
                    return true;
                else
                    return false;
            },
            c_roles() {
                let roles = JSON.parse(JSON.stringify(this.$variables.v.roles));
                if (this.c_role_change_allowed) {
                    switch (this.$user.user.roleID) {
                        case this.$variables.v.role_ids.superadmin:
                            break;
                        case this.$variables.v.role_ids.admin:
                            roles.splice(0, 2);
                            break;
                        case this.$variables.v.role_ids.moderator:
                            roles.splice(0, 3);
                            break;
                        default:
                            roles = [];
                            break;
                    }
                }
                return roles;
            },
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        watch     : {
            dialog() {
                if (this.dialog === false)
                    this.disable_enabler();
            }
        },
        methods   : {
            async update_username() {
                if (this.$refs.r_form_change_username.validate()) {
                    this.enable_username = false;

                    let response = await this.func.ax("/php/administration.php", {
                        func    : "update_username",
                        userid  : this.$user.user.id,
                        token   : this.$user.user.token,
                        id      : this.user.userID, // userID is required because it takes ID of user_systeminfo
                        username: this.user.username.trim(),
                    });
                    await this.check_response(response);

                    this.user = JSON.parse(JSON.stringify(this.user_backup));
                    this.unlock_change_username++;
                }
            },
            async update_email() {
                if (this.$refs.r_form_change_email.validate()) {
                    this.enable_email = false;

                    let response = await this.func.ax("/php/administration.php", {
                        func  : "update_email",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        id    : this.user.userID, // userID is required because it takes ID of user_systeminfo
                        email : this.user.email.trim(),
                    });
                    await this.check_response(response);

                    this.user = JSON.parse(JSON.stringify(this.user_backup));
                    this.unlock_change_email++;
                }
            },
            async update_points() {
                if (this.$refs.r_form_change_points.validate()) {
                    this.enable_points = false;

                    let response = await this.func.ax("/php/administration.php", {
                        func  : "update_points",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        id    : this.user.userID, // userID is required because it takes ID of user_systeminfo
                        points: this.user.points.trim(),
                    });
                    await this.check_response(response);

                    this.user = JSON.parse(JSON.stringify(this.user_backup));
                    this.unlock_change_points++;
                }
            },
            async update_role() {
                if (this.$refs.r_form_change_points.validate()) {
                    this.enable_role = false;

                    let response = await this.func.ax("/php/administration.php", {
                        func  : "update_role",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        id    : this.user.userID, // userID is required because it takes ID of user_systeminfo
                        role  : this.user.roleID,
                    });
                    await this.check_response(response);

                    this.user = JSON.parse(JSON.stringify(this.user_backup));
                    this.unlock_change_role++;
                }
            },
            async check_response(response) {

                await this.$root.check_response(response, async () => {
                    await this.get_user();
                    this.$emit("user_updated", this.user);
                    this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                }, "administration.msg.error");


            },
            async get_user(userid = this.user.userID) {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "get_user",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    id    : userid,
                });
                await this.$root.check_response_permission(response, () => {
                    this.user        = JSON.parse(JSON.stringify(response));
                    this.user_backup = JSON.parse(JSON.stringify(response));
                    this.get_bans();
                }, response);
            },
            async get_bans() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "get_bans",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    id    : this.user.userID,
                });
                await this.$root.check_response_permission(response, () => {
                    this.bans = JSON.parse(JSON.stringify(response));
                }, response);
            },
            async revoke_ban() {
                if (await this.$refs.confirm.open(this.$t("administration.user-administration.dialog-confirm.title"), this.$t("administration.user-administration.dialog-confirm.are-you-sure"))) {
                    let response = await this.func.ax("/php/administration.php", {
                        func  : "revoke_ban",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        ban_id: this.user.ban_id, // ID of the last ban
                    });
                    await this.check_response(response);

                    this.user = JSON.parse(JSON.stringify(this.user_backup));
                }
            },
            async deauthorize_user() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "deauthorize_user",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    id    : this.user.userID,
                });
                await this.$root.check_response(response, async () => {
                    this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                }, "administration.msg.error");
                this.unlock_actions++;
            },
            swing_banhammer() {
                this.$refs.dialog_user_ban.open_dialog();
                this.unlock_ban_user++;
            },
            async user_banned() {
                await this.get_user();
                this.$emit("user_updated", this.user);
            },
            async open_dialog(userid) {
                await this.get_user(userid);
                this.dialog = true;
            },
            disable_enabler() {
                this.enable_username = false;
                this.enable_email    = false;
                this.enable_points   = false;
                this.enable_role     = false;
            },
        },
    }
</script>