<template>
    <v-dialog v-model="dialog" v-if="$root.check_mod()" width="800px">
        <div class="px-4 grey darken-3">
            <dragger></dragger>
            <v-form v-model="valid" ref="r_form_ban_user">
                <v-row>
                    <v-col cols="12">
                        <v-checkbox class="mr-3"
                                    v-model="permanent"
                                    :disabled="!$root.check_admin"
                                    :label="$t('administration.user-administration.ban.ban-permanent')"
                                    :color="$variables.v.theme.secondary"></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="!permanent">
                        <v-dialog
                                ref="dialog"
                                v-model="modal_date"
                                :return-value.sync="ban_expiration_date"
                                width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="ban_expiration_date"
                                        :label="$t('administration.user-administration.ban.expiration-date')"
                                        :color="$variables.v.theme.secondary"
                                        outlined
                                        readonly
                                        clearable
                                        dense
                                        :rules="[rules.required]"
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="ban_expiration_date"
                                           :min="current_date"
                                           scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text
                                       :color="$variables.v.theme.secondary"
                                       @click="modal_date = false">{{$t("date-time-modal.cancel")}}
                                </v-btn>
                                <v-btn text
                                       :color="$variables.v.theme.secondary"
                                       @click="$refs.dialog.save(ban_expiration_date)">
                                    {{$t("date-time-modal.ok")}}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="!permanent">
                        <v-dialog
                                ref="dialog2"
                                v-model="modal_time"
                                :return-value.sync="ban_expiration_time"
                                width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="ban_expiration_time"
                                        :label="$t('administration.user-administration.ban.expiration-time')"
                                        :color="$variables.v.theme.secondary"
                                        outlined
                                        readonly
                                        clearable
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[c_check_banned_date,]"></v-text-field>
                            </template>
                            <v-time-picker
                                    v-if="modal_time"
                                    v-model="ban_expiration_time"
                                    full-width>
                                <v-spacer></v-spacer>
                                <v-btn text
                                       :color="$variables.v.theme.secondary"
                                       @click="modal_time = false">{{$t("date-time-modal.cancel")}}
                                </v-btn>
                                <v-btn text
                                       :color="$variables.v.theme.secondary"
                                       @click="$refs.dialog2.save(ban_expiration_time)">
                                    {{$t("date-time-modal.ok")}}
                                </v-btn>
                            </v-time-picker>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field v-model="reason"
                                      :label="$t('administration.user-administration.ban.reason')"
                                      :rules="[rules.required, rules.min3, rules.max100]"
                                      :color="$variables.v.theme.secondary"
                                      outlined
                                      counter
                                      dense
                                      maxlength="100"></v-text-field>
                    </v-col>

                    <v-col cols="12" class="text-center">
                        <v-btn v-if="!user.banned"
                               depressed
                               :key="unlock_ban_user"
                               class="administration-btn mb-5"
                               :disabled="!valid"
                               @click.once="swing_banhammer">
                            {{$t("administration.user-administration.ban-user")}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        props   : ["user"],
        data() {
            return {
                dialog             : false,
                valid              : false, // Disable Button when form not valid
                permanent          : false,
                reason             : "",
                current_date       : null,
                modal_date         : false,
                modal_time         : false,
                ban_expiration     : "",
                ban_expiration_date: null,
                ban_expiration_time: null,


                unlock_ban_user: 0,

                rules: {
                    required: v => (!!v) || this.$t("rules.required"),
                    min3    : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    max100  : v => (v !== undefined && v.length <= 100) || this.$t("rules.max-100-chars"),
                },
            }
        },
        computed: {
            c_check_banned_date() {
                return () => this.ban_expiration_date !== null || this.ban_expiration_time === null || this.$t("rules.date-required");
            },
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        watch   : {
            dialog() {
                if (this.dialog)
                    this.current_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
                else
                    this.$refs.r_form_ban_user.reset();
            }
        },
        methods : {
            async swing_banhammer() {
                this.ban_expiration = this.func.get_date_time(this.ban_expiration_date, this.ban_expiration_time);

                let response = await this.func.ax("/php/administration.php", {
                    func          : "ban_user",
                    userid        : this.$user.user.id,
                    token         : this.$user.user.token,
                    id            : this.user.userID,
                    reason        : this.reason,
                    permanent     : this.permanent !== undefined ? this.permanent : false,
                    ban_expiration: this.ban_expiration
                });

                await this.$root.check_response(response, () => {
                    this.dialog = false;
                    this.$emit("user_banned");
                    this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                }, "administration.msg.error");

                this.unlock_ban_user++;
            },
            async open_dialog() {
                this.dialog = true;
            },
        },
    }
</script>